export const breakpoints = {
  tablet: `${992}px`,
  desktop: `${1024}px`,
};

const theme = {
  mainBg: "#1E1E1E",
  text: "#D0D0D0",
  grey: "#878787",
};

export default theme;
